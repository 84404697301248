import React, { createContext, useState, useEffect } from "react";
import { BASE_URL } from "../App";
import { useNavigate } from "react-router-dom";
import { isDev } from "../App";

// Create the context
export const UserContext = createContext({
  user: null,
  loading: true,
});

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isDev) {
      setUser({
        id: 9,
        time_created: 1724415833,
        time_updated: 1724415848,
        id_office: 2,
        name: "Ashit Shekhar",
        name_first: "Ashit",
        name_last: "Shekhar",
        role: "Insights Manager",
        email: "ashit.shekhar@1000heads.com",
      });

      setLoading(false);
      return;
    }
    const checkAuth = async () => {
      try {
        const response = await fetch(`${BASE_URL}/auth/`);
        const text = await response.text();
        const data = JSON.parse(text);

        if (data.ok) {
          console.log(data);

          if (!data.user) {
            setUser(null);
            navigate("/login");
          } else {
            setUser(data.user);
          }
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
